@font-face {
  font-family: 'TitilliumWeb';
  src: url('./assets/fonts/TitilliumWeb-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('./assets/fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('./assets/fonts/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('./assets/fonts/TitilliumWeb-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: bolder;
}

$enable-gradients: true;
$enable-negative-margins: true;

$font-family-sans-serif: "TitilliumWeb", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$blue: #0E71B8;
$gray-100: #f2f4f7;
$green: #0B8E36;
$light: #f6f6f6;
$orange: #F9B233;

$primary: #800080;
$secondary: #212529;

$h1-font-size: 32px;
$h2-font-size: 26px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 14px;
$h6-font-size: 12px;
  
$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$link-color: $primary;
$link-hover-color: $secondary;

.cursor-pointer {
  cursor: pointer;
}

.hover-light:hover {
  background: #f2f4f7;
}

.logo {
  width: 200px;
}

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";